type AccommodationSearchCriteria = {
  regions?: number[];
  objectTypes?: number[];
  surface?: number;
  rooms?: number;
}
export type SaleAccommodationSearchCriteria = AccommodationSearchCriteria & {
  priceRange?: [ number, number ];
};

type SearchCriteria = {
  saleAccommodation: SaleAccommodationSearchCriteria;
}

export type SearchPaginationCriteria = {
  first?: number;
  after?: string;
  last?: number;
  before?: string;
}

export type QuerySearchData = {
  where: SearchCriteria;
  pagination: SearchPaginationCriteria;
}

export const parseSearchParams = (params: URLSearchParams, paginationSize: number): QuerySearchData => {
  const regions = params.getAll("region");
  const types = params.getAll("object_type");
  const rooms = params.get("rooms");
  const surface = params.get("surface");
  const priceFrom = params.get("price_from");
  const priceTo = params.get("price_to");
  const afterCursor = params.get("page_after_cursor");
  const beforeCursor = params.get("page_before_cursor");
  return {
    where: {
      saleAccommodation: {
        ...(!!regions && regions.length > 0) && { regions: regions.map(r => +r) },
        ...(!!types && types.length > 0) && { objectTypes: types.map(t => +t) },
        ...(!!rooms) && { rooms: +rooms },
        ...(!!surface) && { surface: +surface },
        ...(!!priceFrom && priceTo) && { priceRange: [ +priceFrom, + priceTo ] }
      }
    },
    pagination: {
      ...(!!afterCursor) && { first: paginationSize, after: afterCursor },
      ...(!!beforeCursor) && { last: paginationSize, before: beforeCursor },
      ...(!afterCursor && !beforeCursor) && { first: paginationSize }
    }
  }
}

export const rebuildSearchParams = (criteria: QuerySearchData): URLSearchParams => {
  const result = new URLSearchParams();
  const { where: { saleAccommodation: filter }, pagination } = criteria;
  (!!filter.regions) && filter.regions.forEach(r => result.append("region", r.toString()));
  (!!filter.objectTypes) && filter.objectTypes.forEach(t => result.append("object_type", t.toString()));
  (!!filter.rooms) && result.set("rooms", filter.rooms.toString());
  (!!filter.surface) && result.set("surface", filter.surface.toString());
  if (!!filter.priceRange) {
    result.set("price_from", filter.priceRange[0].toString());
    result.set("price_to", filter.priceRange[1].toString());
  }
  (!!pagination.after) && result.set("page_after_cursor", pagination.after);
  (!!pagination.before) && result.set("page_before_cursor", pagination.before);
  return result;
}
