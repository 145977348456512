import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { LocaleLink, useLocaleFormatter } from "@ct-react/locale";
import { DataProps } from "../utils/components";
import { ArticleSummary } from "../models/article";
import ParkingSvg from "../assets/componable-svgs/icon-parking.svg";
import BathroomSvg from "../assets/componable-svgs/icon-bathroom.svg";
import BedroomSvg from "../assets/componable-svgs/icon-bedroom.svg";
import "./card.scss";

const splideOptions = {
  type: "slide",
  height: "14em",
  cover: true,
  drag: false,
  start: 0,
  pagination: false,
  rewind: true,
  classes: {
    arrows: 'splide__arrows cardImage-arrowsBlock',
    arrow: 'splide__arrow cardImage-arrow',
  }
};

const transDefs = defineMessages({
  sold: { id: "card-article-sold", defaultMessage: "Vendu" },
  emptyCarousel: { id: "card-article-no-image", defaultMessage: "Aucune image disponible" },
  rooms: { id: "card-article-room", defaultMessage: "{val, plural, =1 {Studio} other {# pièces}}" },
  parking: { id: "card-article-parking", defaultMessage: "{val, plural, =1 {# place} other {# places}}" },
  bathrooms: {
    id: "card-article-bathrooms",
    defaultMessage: "{val, plural, =1 {# salle de bain} other {# salles de bain}}"
  },
  bedrooms: { id: "card-article-bedrooms", defaultMessage: "{val, plural, =1 {# chambre} other {# chambres}}" },
  onDemand: { id: "price-on-request-only", defaultMessage: "Prix sur demande" },
  more: { id: "card-articles-btn-see-more", defaultMessage: "Voir les détails" }
})
const CardArticle = ({ data }: DataProps<ArticleSummary>) => {

  const intl = useIntl();
  const { print } = useLocaleFormatter();

  return (
    <article className="card">

      {data.sold &&
        <div className="chip-sale">{intl.formatMessage(transDefs.sold)}</div>
      }

      <div className="card-carousel">
        {data.images.length > 0
          ? <Splide aria-label="Images" options={splideOptions}>
            {data.images.map((image, i) => (
              <SplideSlide key={i}>
                <img src={image.assets[0].url} alt={image.alternativeText?.value}/>
              </SplideSlide>)
            )}
          </Splide>
          : <div className="empty-img">
            <FontAwesomeIcon icon={faCamera}/>
            <p>{intl.formatMessage(transDefs.emptyCarousel)}</p>
          </div>
        }
      </div>

      <div className="card-info">

        <div className="inline-list piped">
          {!!data.features.rooms && <span>{intl.formatMessage(transDefs.rooms, { val: data.features.rooms })}</span>}
          {!!data.features.livingSpace && <span>{`${data.features.livingSpace} m²`}</span>}
          <span>{data.address.city}</span>
        </div>
        <h5 className="card-title">
          <span className="truncate">{data.title.value}</span>
        </h5>

        <div className="inline-list">
          {!!data.features.parkings &&
            <span className="with-icon">
              <ParkingSvg />
              {intl.formatMessage(transDefs.parking, { val: data.features.parkings })}
            </span>
          }
          {!!data.features.bathrooms &&
            <span className="with-icon">
              <BathroomSvg />
              {intl.formatMessage(transDefs.bathrooms, { val: data.features.bathrooms })}
            </span>
          }
          {!!data.features.bedrooms &&
            <span className="with-icon">
              <BedroomSvg />
              {intl.formatMessage(transDefs.bedrooms, { val: data.features.bedrooms })}
            </span>
          }
        </div>

        <div className="card-price">
          {data.price.onDemand
            ? intl.formatMessage(transDefs.onDemand)
            : print.price(data.price.value, { trailingZeroDisplay: "stripIfInteger" })
          }
          <LocaleLink className="open-article"
                      to={`/bien/${data.id}`}>
            {intl.formatMessage(transDefs.more)}
          </LocaleLink>
        </div>

      </div>

    </article>);

};

export const CardArticleSkeleton = () => (
  <div className="card">
    <Skeleton className="card-carousel" inline={true}/>
    <div className="card-info">
      <Skeleton width="50%"/>
      <Skeleton count={2} className="skeleton-card-title"/>
      <Skeleton/>
      <div className="card-price">
        <Skeleton width="8rem"/>
        <Skeleton className="skeleton-card-btn"/>
      </div>
    </div>
  </div>);

export default CardArticle;
