import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useMemo, useState } from "react";
import { QuerySearchData } from "../models/search";

export type SearchContextProps = {
  paginationSize: number,
  criteria: QuerySearchData,
  onCriteriaChange: Dispatch<SetStateAction<QuerySearchData>>
}

export const SearchContext = createContext<SearchContextProps>({
  paginationSize: 8,
  criteria: {
    where: { saleAccommodation: {} },
    pagination: { first: 8 }
  },
  onCriteriaChange: () => void 0
});

export const SearchContextProvider = ({ paginationSize, children }: PropsWithChildren & { paginationSize: number }) => {

  const [ criteria, setCriteria ] = useState<QuerySearchData>({
    where: { saleAccommodation: {} },
    pagination: { first: paginationSize }
  });

  const contextValue = useMemo(() => ({
    paginationSize,
    criteria,
    onCriteriaChange: setCriteria
  }), [ paginationSize, criteria ]);

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>);

};
