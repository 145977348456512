import { defineMessages, useIntl } from "react-intl";
import { FormEvent, MouseEvent, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { classNames } from "@ct-react/core";
import { useLocaleFormatter } from "@ct-react/locale";
import { SaleAccommodationSearchCriteria } from "../../models/search";
import Arrow from "../../assets/componable-svgs/search-arrow.svg";
import "./search-form.scss";
import { useCheckMobileScreen } from "../../react/is-mobile-screen";

const transDefs = defineMessages({
  filterTitle: { id: "searchmodule-filter", defaultMessage: "Filtres" },
  regionTitle: { id: "searchmodule-regions", defaultMessage: "Destination" },
  regionLabel: { id: "choose-Regions", defaultMessage: "Quelle destination ?" },
  typeTitle: { id:"searchmodule-types-of-object", defaultMessage: "Types" },
  typeLabel: { id:"choose-type-of-object", defaultMessage: "Quel(s) type(s) de bien ?" },
  priceTitle: { id:"searchmodule-price", defaultMessage: "Prix" },
  priceLabel: { id:"searchmodule-choose-price",defaultMessage: "Quel prix désirez-vous ?" },
  roomTitle: { id:"searchmodule-minRoom", defaultMessage: "Pièces" },
  roomLabel: { id:"choose-minRoom",defaultMessage: "Combien de pièces minimum ?" },
  surfaceTitle: { id: "searchmodule-surface", defaultMessage: "Surface" },
  surfaceLabel: { id:"choose-surface", defaultMessage: "Combien de m² minimum ?" },
  submit: { id: "btn-search", defaultMessage: "Rechercher" }
});

const SEARCHABLE_CRITERIA_GQL_DATA = gql`
  query ForForm {
    regions: searchableAccommodationRegions(accommodationType: SALE) { id name }
    objectTypes: searchableAccommodationObjectTypes(accommodationType: SALE) { id name }
  }
`;

type DataRef = { id: number, name: string };

type SearchFormProps = {
  criteria: SaleAccommodationSearchCriteria;
  mobileView: boolean;
  onCriteriaChange: (criteria: SaleAccommodationSearchCriteria) => void;
  onMobileViewChange?: (mobileView: boolean) => void;
}

type FilterItemProps = PropsWithChildren & {
  className?: string;
  title: string;
  label: string;
  value?: string;
  onReset: () => void;
}

const SearchForm = (
  {
    criteria,
    mobileView,
    onCriteriaChange,
    onMobileViewChange = () => void 0
  }: SearchFormProps) => {

  const intl = useIntl();
  const { transLib } = useLocaleFormatter();
  const isMobile = useCheckMobileScreen();

  const [ editableCriteria, setEditableCriteria ] = useState<SaleAccommodationSearchCriteria>({});
  useEffect(() => setEditableCriteria(criteria), [ criteria ]);

  const maxPriceFilter = 5000000;
  const priceMinRef = useRef<HTMLInputElement>(null);
  const priceMaxRef = useRef<HTMLInputElement>(null);

  // data

  const { data } = useQuery(SEARCHABLE_CRITERIA_GQL_DATA, { ssr: true });
  const allRegions = useMemo<DataRef[]>(() => data?.regions || [], [ data ]);
  const allTypes = useMemo<DataRef[]>(() => data?.objectTypes || [], [ data ]);

  // format

  const regionValue = useMemo(() => {
    if (!editableCriteria.regions) return undefined;
    return allRegions.find(r => r.id === editableCriteria.regions![0])?.name;
  }, [ editableCriteria, allRegions ]);

  const typesValue = useMemo(() => {
    if (!editableCriteria.objectTypes) return undefined;
    return editableCriteria.objectTypes.map(id => allTypes.find(t => t.id === id)?.name || undefined).filter(str => !!str).join(", ");
  }, [ editableCriteria, allTypes ]);

  const priceValue = useMemo(() => {
    if (!editableCriteria.priceRange ||
      (editableCriteria.priceRange[0] === 0 && editableCriteria.priceRange[1] === maxPriceFilter)) return undefined;
    const from = intl.formatNumber(editableCriteria.priceRange![0], { notation: "compact" });
    const to = intl.formatNumber(editableCriteria.priceRange![1], { notation: "compact" });
    return `${from} - ${to}`;
  }, [ editableCriteria ]);

  const roomsValue = useMemo(() => {
    if (!editableCriteria.rooms) return undefined;
    switch (editableCriteria.rooms) {
      case 1:
        return transLib.match("objectTypes", 8);
      case 6:
        return "6+";
      default:
        return editableCriteria.rooms.toString();
    }
  }, [ editableCriteria ]);

  const surfaceValue = useMemo(() => {
    if (!editableCriteria.surface || editableCriteria.surface === 0) return undefined;
    return `${editableCriteria.surface} m²`;
  }, [ editableCriteria ]);

  // dom interactions

  const onRegionChange = (value?: number) =>
    setEditableCriteria({ ...editableCriteria, regions: !!value ? [ value ] : undefined });

  const onTypesChange = (value?: number[]) =>
    setEditableCriteria({ ...editableCriteria, objectTypes: value });

  const onPriceChange = (value?: [ number, number]) => {
    const priceRange = (!!value && value[0] > value[1]) ? value.reverse() as [ number, number ] : value;
    setEditableCriteria({ ...editableCriteria, priceRange });
  }

  const onRoomChange = (value?: number) =>
    setEditableCriteria({ ...editableCriteria, rooms: value });

  const onSurfaceChange = (value?: number) =>
    setEditableCriteria({ ...editableCriteria, surface: value })
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onCriteriaChange(editableCriteria);
  }

  // rendering

  const wrapperClasses = classNames("search-module", { "mobile-view": mobileView && isMobile });

  return (
    <div className={wrapperClasses}>

      {mobileView &&
        <div className="mobile-header">
          <h4>{intl.formatMessage(transDefs.filterTitle)}</h4>
          <FontAwesomeIcon icon={faXmark} onClick={() => onMobileViewChange(false)} />
        </div>
      }

      <form onSubmit={onSubmit}>

        <FilterItem className="region"
                    title={intl.formatMessage(transDefs.regionTitle)}
                    label={intl.formatMessage(transDefs.regionLabel)}
                    value={regionValue}
                    onReset={() => onRegionChange()}>
          <ul className="region-list">
            {allRegions.map((r, i) => <li key={i} onClick={() => onRegionChange(r.id)}>{r.name}</li>)}
          </ul>
        </FilterItem>

        <FilterItem className="types"
                    title={intl.formatMessage(transDefs.typeTitle)}
                    label={intl.formatMessage(transDefs.typeLabel)}
                    value={typesValue}
                    onReset={() => onTypesChange()}>
          <ul className="types-list">
            {allTypes.map((t, i) => (
              <li key={i}>
                <input type="checkbox" id={t.id.toString()}
                       checked={editableCriteria.objectTypes?.includes(t.id)}
                       onChange={e => {
                         const filtered = editableCriteria.objectTypes?.filter(id => id !== t.id) || [];
                         onTypesChange([ ...filtered, ...(e.currentTarget.checked ? [ t.id ] : []) ]);
                       }} />
                <label htmlFor={t.id.toString()}>{t.name}</label>
              </li>)
            )}
          </ul>
        </FilterItem>

        <FilterItem className="price"
                    title={intl.formatMessage(transDefs.priceTitle)}
                    label={intl.formatMessage(transDefs.priceLabel)}
                    value={priceValue}
                    onReset={() => onPriceChange()}>
          <div className="min-max-range">
            <input type="range" ref={priceMinRef}
                   min={0} max={5000000} step={50000}
                   value={!!editableCriteria.priceRange ? editableCriteria.priceRange[0] : 0}
                   onChange={e => onPriceChange([ +e.currentTarget.value, +priceMaxRef.current!.value ])} />
            <input type="range" ref={priceMaxRef}
                   min={0} max={5000000} step={50000}
                   value={!!editableCriteria.priceRange ? editableCriteria.priceRange[1] : 5000000}
                   onChange={e => onPriceChange([ +priceMinRef.current!.value, +e.currentTarget.value ])} />
          </div>
        </FilterItem>

        <FilterItem className="room"
                    title={intl.formatMessage(transDefs.roomTitle)}
                    label={intl.formatMessage(transDefs.roomLabel)}
                    value={roomsValue}
                    onReset={() => onRoomChange()}>
          <div className="rooms-list">
            <button type="button"
                    className={classNames({ active: editableCriteria.rooms === 1})}
                    onClick={() => onRoomChange(1)}>{transLib.match("objectTypes", 8)}</button>
            <button type="button"
                    className={classNames({ active: editableCriteria.rooms === 2})}
                    onClick={() => onRoomChange(2)}>2</button>
            <button type="button"
                    className={classNames({ active: editableCriteria.rooms === 3})}
                    onClick={() => onRoomChange(3)}>3</button>
            <button type="button"
                    className={classNames({ active: editableCriteria.rooms === 4})}
                    onClick={() => onRoomChange(4)}>4</button>
            <button type="button"
                    className={classNames({ active: editableCriteria.rooms === 5})}
                    onClick={() => onRoomChange(5)}>5</button>
            <button type="button"
                    className={classNames({ active: editableCriteria.rooms === 6})}
                    onClick={() => onRoomChange(6)}>6+</button>
          </div>
        </FilterItem>

        <FilterItem className="surface"
                    title={intl.formatMessage(transDefs.surfaceTitle)}
                    label={intl.formatMessage(transDefs.surfaceLabel)}
                    value={surfaceValue}
                    onReset={() => onSurfaceChange()}>
          <input type="range" min={0} max={250} step={10}
                 value={editableCriteria.surface || 0}
                 onChange={e => onSurfaceChange(+e.currentTarget.value)} />
        </FilterItem>

        <button type="submit">{intl.formatMessage(transDefs.submit)}</button>

      </form>

    </div>);

}

const FilterItem = (
  {
    className,
    title,
    label,
    value,
    onReset,
    children
  }: FilterItemProps) => {

  const [ open, setOpen ] = useState<boolean>(false);

  const reset = (e: MouseEvent<SVGElement>) => {
    e.stopPropagation();
    onReset();
  }

  const wrapperClasses = classNames("one-filter", className);
  const resetClasses = classNames("reset", { disabled: !value });
  const selectorClasses = classNames("filter-selector", { open });

  return (
    <div className={wrapperClasses}
         onClick={() => setOpen(prev => !prev)}>

      <div className="filter-value">
        <div className="value">
          {title}
          {!!value && <span className="selected">{value}</span>}
        </div>
        <span className={resetClasses}>
            <FontAwesomeIcon icon={faXmark} onClick={reset} />
          </span>
        <span className="arrow"><Arrow className="arrow" /></span>
      </div>

      <div className={selectorClasses}>
        <span className="selector-arrow" />
        <div className="selector-content" onClick={e => e.stopPropagation()}>
          <span className="description">{label}</span>
          <div className="selector">{children}</div>
        </div>
      </div>

    </div>);

}

export default SearchForm;
