import React from "react";
import LogoSvg from "../../../assets/componable-svgs/logo-aci.svg";
import "./footer.scss";

const Footer = () => (
  <footer id="contact">
    <div className="home-link"
         onClick={() => scrollTo(0, 0)}>
      <LogoSvg className="logo-svg"/>
    </div>
    <ul className="address">
      <li>Route du Lac 42</li>
      <li>1938 Champex-Lac</li>
      <li><a href="tel:0275650565">+41 27 565 05 65</a></li>
      <li><a href="mailto:contact@auclubimmobilier.ch">contact@auclubimmobilier.ch</a></li>
    </ul>
    <p className="copyright">
      © 2023 Au Club Immobilier par <a href="https://www.creative-technologies.ch/" target="_blank" rel="external">C.T.
      Creative Technologies SA</a> & UX/UI design par <a href="https://www.gladys-ancay.com/" target="_blank"
                                                         rel="external">Gladys Ançay</a>
    </p>
  </footer>);

export default Footer;
