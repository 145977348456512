import React, { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faMap } from "@fortawesome/free-solid-svg-icons";
import { defineMessages, useIntl } from "react-intl";
import { classNames } from "@ct-react/core";
import { ARTICLE_SUMMARY_FRAGMENT, PAGINATION_INFO_FRAGMENT } from "@shared/gql/fragments";
import { SearchContext } from "../../react/context";
import {
  parseSearchParams,
  rebuildSearchParams,
  SaleAccommodationSearchCriteria,
  SearchPaginationCriteria
} from "../../models/search";
import { MapAsyncPopupMarker, MapContainer } from "../../bundles/map-implement";
import { Section } from "../../components/wrapper/section";
import SearchForm from "../../components/search/search-form";
import ResultPagination from "../../components/search/pagination";
import CardArticle, { CardArticleSkeleton } from "../../components/card";
import FilterSvg from "../../assets/componable-svgs/filter-icon.svg";
import "./articles.scss";
import { useCheckMobileScreen } from "../../react/is-mobile-screen";

const transDefs = defineMessages({
  title: { id: "title-bien-en-vente", defaultMessage: "Nos biens {break} à la vente" },
  mapToggle: { id: "articles-map", defaultMessage: "Carte" },
  listToggle: { id: "btn-list", defaultMessage: "Liste" },
  map: { id:"articles-see-map", defaultMessage: "Voir la carte" },
  filters: { id:"articles-sort-filters", defaultMessage: "Trier et filtrer" },
  emptyTitle: { id:"articles-no-accomodations-correspond", defaultMessage: "Aucun bien ne correspond aux filtres choisis." },
  emptyDetail: { id:"articles-modify-your-filters", defaultMessage: "Veuillez réinitialiser les filtres ou les modifier." }
});

export const SEARCH_GQL_DATA = gql`
  ${ARTICLE_SUMMARY_FRAGMENT}
  ${PAGINATION_INFO_FRAGMENT}
  query Search($where: AnyArticleFilterInput, $first: Int, $after: String, $last: Int, $before: String) {
    search(where: $where, first: $first, after: $after, last: $last, before: $before, paginationLinksLength: 3) {
      edges {
        cursor
        node { ... on SaleAccommodationArticle { ...ArticleSummaryFields} }
      }
      accommodationMapMarkers { id coordinates }
      paginationInfo { ...PaginationFields }
    }
  }
`;

const Articles = () => {

  const intl = useIntl();

  const [ searchParams, setSearchParams ] = useSearchParams();
  const { paginationSize, criteria, onCriteriaChange } = useContext(SearchContext);
  const isMobile = useCheckMobileScreen();

  const [ showMobileFilters, setShowMobileFilters ] = useState<boolean>(false);
  const [ showMap, setShowMap ] = useState<boolean>(false);

  const containerRef = useRef(null);
  const mapRef = useRef<any>(null);

  // data

  const [ fetch, { loading, data } ] = useLazyQuery(SEARCH_GQL_DATA);

  // on loading: only override context with search params if exists;
  useEffect(() => {
    searchParams.size > 0 && onCriteriaChange(parseSearchParams(searchParams, paginationSize));
  }, []);

  // on criteria change: update search params with right values and fetch query
  useEffect(() => {
    setSearchParams(rebuildSearchParams(criteria));
    fetch({ variables: { where: criteria.where, ...criteria.pagination }}).then();
  }, [ criteria ]);

  // dom interaction

  const toggleMapView = () => setShowMap(prev => !prev);

  const onPaginate = (pagination: SearchPaginationCriteria) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    onCriteriaChange({ ...criteria, pagination });
  }

  const search = (saleAccommodation: SaleAccommodationSearchCriteria) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    onCriteriaChange({ ...criteria, where: { saleAccommodation }, pagination: { first: paginationSize } });
    showMobileFilters && setShowMobileFilters(false);
  }

  // rendering

  return (<>
    <Section className="articles-header">
      <SearchForm criteria={criteria.where.saleAccommodation}
                  mobileView={showMobileFilters}
                  onCriteriaChange={search}
                  onMobileViewChange={setShowMobileFilters} />
      <div className="articles-titles">
        <h2>{intl.formatMessage(transDefs.title, { break: <br/> })}</h2>
        <div className="toggle-map">
          {intl.formatMessage(transDefs.mapToggle)}
          <label className="switch">
            <input type="checkbox"
                   className="toggle-btn"
                   checked={showMap}
                   onChange={toggleMapView}/>
            <span className="slider round"/>
          </label>
        </div>
      </div>
    </Section>
    <Section id="articles">
      <div ref={containerRef}
           className={classNames("articles-container", { showMap, isMobile })}>

        <div className={classNames("articles", { empty: !!data?.search && data.search.edges.length === 0})}>
          {(loading || !data?.search)
            ? Array.from({ length: paginationSize }).map((_, i) => <CardArticleSkeleton key={i} />)
            : data.search.edges.length > 0
              ? data.search.edges.map((edge: any, i: number) => <CardArticle key={i} data={edge.node} />)
              : <>
                <h4>{intl.formatMessage(transDefs.emptyTitle)}</h4>
                <p>{intl.formatMessage(transDefs.emptyDetail)}</p>
              </>
          }
        </div>

        <div className="articles-map">
          <MapContainer ref={mapRef.current}
                        className="overloaded-map"
                        center={[ 46.02835544029722, 7.116572852875435 ]}
                        zoom={10}
                        clusterize={true}
                        zoomControlPosition="bottomleft"
                        layersControlPosition="bottomright">
            {(!loading && !!data?.search) && data.search.accommodationMapMarkers.map((marker: any, i: number) =>
              <MapAsyncPopupMarker key={i} {...marker} />
            )}
          </MapContainer>
        </div>

        <div className="articles-map-button"
             onClick={() => setShowMap(prev => !prev)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{intl.formatMessage(transDefs.listToggle)}</span>
        </div>

        <div className="articles-mobile-actions">
          <div onClick={() => setShowMap(true)}>
            <FontAwesomeIcon icon={faMap} />
            {intl.formatMessage(transDefs.map)}
          </div>
          <div onClick={() => setShowMobileFilters(true)}>
            <FilterSvg />
            {intl.formatMessage(transDefs.filters)}
          </div>
        </div>

      </div>
    </Section>
    {(!loading && !!data?.search.paginationInfo) &&
      <ResultPagination data={data.search.paginationInfo}
                        onPageChange={onPaginate} />
    }
  </>);

}

export default Articles;
