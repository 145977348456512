const emailChain = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const textChain = /[^a-zéàèüûúùöôõóòäêâîïëçñA-ZÂÀÁÄÃÜÛÚÙÊËÉÈÔÓÒÖÕîïíì\s']/gmu;
const telChain = /[^+0-9]/gm;
const textareaChain = /script/gm;

export type FormData = {
  name: string;
  nameValid: boolean;
  surname: string;
  surnameValid: boolean;
  email: string;
  emailValid: boolean;
  tel: string;
  telValid: boolean;
  message: string;
  messageValid: boolean;
}

export const buildEmptyFormData = (): FormData => ({
  name: "",
  nameValid: false,
  surname: "",
  surnameValid: false,
  email: "",
  emailValid: false,
  tel: "",
  telValid: false,
  message: "",
  messageValid: false
} as FormData);

export const contactFormReducer = (state: FormData, action: { type: string, value: any }): FormData => {
  switch (action.type) {
    case "name":
      return {
        ...state,
        name: action.value,
        nameValid: action.value !== "" && !textChain.test(action.value)
      };
    case "surname":
      return {
        ...state,
        surname: action.value,
        surnameValid: action.value !== "" && !textChain.test(action.value)
      };
    case "email":
      return {
        ...state,
        email: action.value,
        emailValid: action.value !== "" && emailChain.test(action.value)
      };
    case "tel":
      return {
        ...state,
        tel: action.value,
        telValid: action.value !== "" && !telChain.test(action.value)
      };
    case "message":
      return {
        ...state,
        message: action.value,
        messageValid: action.value !== "" && !textareaChain.test(action.value)
      };
    case "reset":
      return buildEmptyFormData();
    default:
      throw new Error(`form reducer: unknown action ${action.type}`);
  }
}
