import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { IntlProvider } from 'react-intl';
import { useLocation } from "react-router";
import { useAppConfig } from "@ct-react/core";
import { defaultLocale, useLocaleContext } from "@ct-react/locale";
import { getIntlMessages } from "./i18n";
import LocaleRoutes from "./routes";
import { SearchContextProvider } from "./react/context";
import Menu from "./components/layout/menu/menu";
import Footer from "./components/layout/footer/footer";
import { ButtonToTop } from "./components/common";
import "./styles/global.scss";

const App = () => {

  const { baseUrl, gaTag, env } = useAppConfig();
  const location = useLocation();
  const { locale } = useLocaleContext();

  useEffect(() => {
    !!gaTag && ReactGA.initialize(gaTag, { testMode: env !== "production" });
  }, []);

  useEffect(() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }), [ location.pathname ]);

  return (
    <IntlProvider locale={locale.code}
                  defaultLocale={defaultLocale.code}
                  messages={getIntlMessages(locale)}>
      <Helmet>
        <html lang={locale.code}/>
        <title>Au Club Immobilier</title>
        <link rel="canonical" href={baseUrl}/>
        <meta property="og:locale" content={locale.code}/>
        <meta property="og:title" content="Au Club Immobilier"/>
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content="auclubimmobilier.ch"/>
        <meta name="keywords"
              content="Au club immobilier, Agence, Immobilier, Agence immobilière, Champex-Lac, Entremont, Valais, Suisse, Vente, Vente immobilière, Acquisition, Acquisition immobilière, Location, Location immobilière, Gestion administrative"/>
      </Helmet>
      <SearchContextProvider paginationSize={8}>
        <Menu/>
        <main>
          <LocaleRoutes />
        </main>
        <ButtonToTop />
        <Footer />
      </SearchContextProvider>
    </IntlProvider>);

}

export default App
