import React, { FunctionComponent, RefObject, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import gsap from "gsap";
import { Header, Section } from "../../components/wrapper/section";
import { ImageBox, Textbox, TwoColumn } from "../../components/wrapper/column";

import "./champex.scss";

import headerImg from "../../assets/champex-lac.jpg";
import image1 from "../../assets/champex-lac-vue copie.jpg";
import image2 from "../../assets/chalet-champex-lac.png";
import { classNames } from "@ct-react/core";

const Champex: FunctionComponent = () => {
  const intl = useIntl();
  const [ summer, setSummer ] = useState<boolean>(true);
  const [ section_1_Y, setSection_1_Y ] = useState<number>(0);

  const section_1_image = useRef<HTMLDivElement | null>(null);

  //Init intersectObserver
  useEffect(() => {
    if (!!section_1_image) {
      intersect_section_1(section_1_image)
    }
  }, []);

  //Animate section 1 images
  useEffect(() => {
    const image1 = document.querySelector(".season .image:nth-child(1)")
    const image2 = document.querySelector(".season .image:nth-child(2)")
    if (section_1_Y > 0) {
      gsap.to(image1, {
        x: 0,
        autoAlpha: 1,
        duration: 1,
      })
      gsap.to(image2, {
        x: "-50%",
        autoAlpha: 1,
        duration: 1,
        delay: 1
      })
    }
  }, [ section_1_Y ]);

  //intersect observer section 1
  const intersect_section_1 = (elementRef: RefObject<Element>) => {
    if (elementRef != null) {
      const node = elementRef?.current! // DOM Ref
      const observer = new IntersectionObserver(
        ([ entry ]: any) => {
          setSection_1_Y(entry.intersectionRect.x)
        },
        { rootMargin: window.innerWidth > 900 ? "-50%" : "-40%" }
      );
      elementRef && observer.observe(node)
      return () => observer.disconnect()
    }
  };

  return (
    <>
      <Header subtitle={intl.formatMessage({ id: "title-agence-champex-lac", defaultMessage: "Agence champex-lac" })}
              img={headerImg}>
        {intl.formatMessage({ id: "title-champex-lac", defaultMessage: "Champex-lac" })}
      </Header>
      <Section id="season" className="season" color="var(--primary)">
        <TwoColumn>
          <div ref={section_1_image} className="left-box">
            <ImageBox title={intl.formatMessage({
              id: "title-lieu-plein-de-charme",
              defaultMessage: "Un lieu plein{break} de charme"
            }, { break: <br/> })} image1={image1} image2={image2}/>
          </div>
          <div className="right-box">
            <Textbox>
              <div className="season-btn">
                <button onClick={() => setSummer(true)} className={classNames({ active: summer })}>
                                    <span>
                                        {intl.formatMessage({ id: "champex-lac-btn-ete", defaultMessage: "été" })}
                                    </span>
                  <span>
                                        {intl.formatMessage({ id: "champex-lac-btn-ete", defaultMessage: "été" })}
                                    </span>
                </button>
                <button onClick={() => setSummer(false)} className={classNames({ active: !summer })}>
                                    <span>
                                        {intl.formatMessage({ id: "champex-lac-btn-hiver", defaultMessage: "hiver" })}
                                    </span>
                  <span>
                                        {intl.formatMessage({ id: "champex-lac-btn-hiver", defaultMessage: "hiver" })}
                                    </span>
                </button>
              </div>
              <p>
                {intl.formatMessage({
                  id: "champex-lac-paragraphe-1",
                  defaultMessage: "En été, laissez-vous tenter par une partie de pêche à la ligne, des balades sur le lac en barque ou pédalo, une excursion le long d’un bisse ou une randonnée sportive à pied ou à vélo. Ne manquez pas la visite du jardin alpin et la découverte du fort d’artillerie."
                })}
              </p>
              {summer &&
                <div className="season-bloc">
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M56.4478 55.7163L54.7194 57.0189L52.806 57.9924M4.55225 55.7163L5.58449 56.4547C7.28158 57.6687 9.31591 58.3214 11.4025 58.3214H45.5224"
                        stroke="var(--black)" strokeWidth="2.4" strokeLinecap="round"/>
                      <path
                        d="M26.8293 34.3208L25.0663 44.8425C25.0019 45.227 24.8648 45.5945 24.6627 45.9249L20.1395 53.3185C19.5924 54.2129 20.0981 55.3967 21.1213 55.6168V55.6168C21.6714 55.7351 22.237 55.5327 22.587 55.0923L28.3468 47.8458C28.6124 47.5116 28.8228 47.1356 28.97 46.7323L31.8368 38.873"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M36.8309 42.0177C37.3634 42.3709 37.695 42.9572 37.7235 43.5955L38.1888 54.0491C38.2322 55.0238 39.0351 55.7917 40.0108 55.7917V55.7917C41.0181 55.7917 41.8346 54.9752 41.8346 53.968V41.8615C41.8346 41.3673 41.6517 40.8907 41.3211 40.5234L33.7376 32.0985L34.0058 23.0767L49.4145 29.4438C50.2683 29.7966 51.246 29.3856 51.5912 28.5287V28.5287C51.8966 27.7708 51.6058 26.9034 50.9054 26.4826L30.9057 14.4679C30.2459 14.0716 29.4514 13.9665 28.7113 14.1776V14.1776C27.5555 14.5072 26.7423 15.5423 26.6957 16.7434L26.0806 32.5832C26.0265 33.978 26.7033 35.3002 27.8666 36.0717L36.8309 42.0177Z"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M31.8625 11.4773C34.4178 11.4773 36.4894 9.35574 36.4894 6.73865C36.4894 4.12157 34.4178 2 31.8625 2C29.3071 2 27.2356 4.12157 27.2356 6.73865C27.2356 9.35574 29.3071 11.4773 31.8625 11.4773Z"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path d="M49.1643 17.4775L49.1643 25.3222M49.1643 49.7984L49.1643 29.0381" stroke="var(--black)"
                            strokeWidth="2.4"/>
                      <path
                        d="M47.1903 52.0574L49.1643 49.3433L51.1382 52.0574C52.2221 53.5479 52.806 55.3435 52.806 57.1864V59.3582C52.806 61.3695 51.1756 63 49.1643 63C47.1529 63 45.5225 61.3695 45.5225 59.3582V57.1864C45.5225 55.3435 46.1064 53.5479 47.1903 52.0574Z"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M32.3208 15.2014L47.7517 13.2231C48.9284 13.0722 49.9312 14.0719 49.7841 15.249V15.249C49.686 16.0339 49.091 16.6649 48.3132 16.809L37.3283 18.8432"
                        stroke="var(--black)" strokeWidth="2.4"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-escalade", defaultMessage: "Escalade" })}
                    </p>
                  </div>
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.9971 37.5L20.0606 49.0566C19.9898 49.479 19.8393 49.8826 19.6173 50.2455L14.6492 58.3664C14.0482 59.3486 14.6038 60.649 15.7276 60.8906V60.8906C16.3318 61.0206 16.953 60.7983 17.3375 60.3147L23.7032 52.3057C23.9689 51.9715 24.1793 51.5955 24.3264 51.1922L27.4971 42.5"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M33.0657 46.009C33.5982 46.3622 33.9298 46.9485 33.9582 47.5867L34.4738 59.1684C34.5215 60.2391 35.4033 61.0825 36.475 61.0825V61.0825C37.5813 61.0825 38.4782 60.1857 38.4782 59.0794V45.7066C38.4782 45.2124 38.2953 44.7358 37.9647 44.3685L29.5847 35.0588L32.0289 25.5L40.5289 29L49.4598 27.9675C50.1047 27.8929 50.6572 27.4716 50.8998 26.8694V26.8694C51.3394 25.7782 50.613 24.5694 49.4431 24.4453L40.5289 23.5L31.6483 16.1028C30.8287 15.4201 29.7247 15.1867 28.699 15.4793V15.4793C27.6058 15.7911 26.7574 16.6553 26.4659 17.7541L21.8146 35.2849C21.3748 36.9426 22.0407 38.6961 23.4699 39.6441L33.0657 46.009Z"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M27.0288 16.5L24.9126 15.1533C22.9355 13.8952 20.3498 14.179 18.6927 15.8361L16.7073 17.8215C15.9423 18.5865 15.4461 19.5789 15.2931 20.6499L14.3441 27.2929C13.9138 30.3051 16.2511 33 19.2939 33H23.0288"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M33.5253 12.4095C36.332 12.4095 38.6073 10.0793 38.6073 7.20475C38.6073 4.33025 36.332 2 33.5253 2C30.7186 2 28.4434 4.33025 28.4434 7.20475C28.4434 10.0793 30.7186 12.4095 33.5253 12.4095Z"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path d="M44.0288 4.5L28.5288 7.5" stroke="var(--black)" strokeWidth="2.4"/>
                      <path d="M50.9363 14.5L50.9363 60.5" stroke="var(--black)" strokeWidth="2.4"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-randonne", defaultMessage: "Randonnée" })}
                    </p>
                  </div>
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.4126 45.5654L20.455 52.865C20.5207 53.3251 20.43 53.7923 20.1979 54.1895L16.3727 60.7343C15.9045 61.5354 16.3607 62.5748 17.2659 62.7695V62.7695C17.7274 62.8688 18.2036 62.7163 18.5209 62.3677L23.8604 56.502C24.2883 56.0319 24.5554 55.4338 24.6226 54.7948L25.3047 48.315"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M30.8396 50.6421C31.2785 50.8046 31.6465 51.1159 31.8796 51.5217L37.5985 61.4805C38.0464 62.2604 39.0413 62.5301 39.8218 62.0833V62.0833C40.5398 61.6723 40.8359 60.7887 40.5107 60.028L35.0377 47.2267C34.8734 46.8422 34.5926 46.5189 34.2349 46.3023L23.9856 40.0944L23.0459 33.2333L36.2247 34.2224C37.1197 34.2895 37.8484 33.5141 37.7259 32.625V32.625C37.6491 32.0678 37.2481 31.6096 36.706 31.4596L19.8964 26.8069C18.8112 26.5066 17.6486 26.8207 16.8623 27.6267V27.6267C16.4443 28.0552 16.2591 28.6593 16.3651 29.2485L19.0513 44.1783C19.3021 45.5721 20.2712 46.7294 21.5993 47.2211L30.8396 50.6421Z"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M34.2033 30.6389C29.8824 21.9972 29.0968 10.9986 35.3818 0M36.1673 34.1742L38.5241 39.2806"
                        stroke="var(--black)" strokeWidth="1.5"/>
                      <path
                        d="M53.2038 37.709C49.7575 34.3994 48.7521 29.1174 51.5718 26.7104C57.2113 28.8166 55.3221 36.2047 54.3822 37.709L57.1319 39.9489H54.3822L52.0254 40.8515C52.0254 40.8515 52.8905 39.2134 53.2038 37.709Z"
                        fill="#020202"/>
                      <path
                        d="M34.9888 0C35.7744 6.2849 37.3456 5.8921 43.6305 7.46332C49.9154 9.03455 38.9168 12.5698 42.8449 16.1051C44.8269 17.8888 50.1922 17.4144 50.8469 17.2835C55.2463 16.9692 53.2037 23.6993 51.6325 27.1036"
                        stroke="var(--black)"/>
                      <path
                        d="M18.6487 23.1046C20.8537 23.1046 22.6412 21.2739 22.6412 19.0157C22.6412 16.7574 20.8537 14.9268 18.6487 14.9268C16.4437 14.9268 14.6562 16.7574 14.6562 19.0157C14.6562 21.2739 16.4437 23.1046 18.6487 23.1046Z"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path d="M15.8872 20.8188L25.7074 13.3555" stroke="var(--black)" strokeWidth="1.5"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-peche", defaultMessage: "Pêche" })}
                    </p>
                  </div>
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.02734 19.5495L10.9285 7.8066L32.7363 29.0342L55.4342 6L63.0001 15.4847"
                            stroke="var(--black)" strokeWidth="2.6" strokeLinecap="round"/>
                      <path d="M4.69775 15.0327L10.0384 20.4525L13.1538 17.291L17.1593 21.3558L20.7198 17.7426"
                            stroke="var(--black)"/>
                      <path d="M44.3079 17.7431L47.8683 21.3563L54.5442 14.5815L56.7695 16.8398L60.3299 13.2266"
                            stroke="var(--black)"/>
                      <path d="M1.13721 27.6792C12.7087 37.1639 50.9836 35.8089 63.0001 27.6792" stroke="var(--black)"
                            strokeWidth="2.6" strokeLinecap="round"/>
                      <path d="M26.2566 51.6137V33.9004" stroke="var(--black)" strokeWidth="2.6"/>
                      <path
                        d="M18.9395 49.8101L20.1941 53.361C20.3148 53.79 20.6767 54.0965 21.1291 54.1578L26.0604 54.7782"
                        stroke="var(--black)" strokeWidth="2.4" strokeLinecap="round"/>
                      <path
                        d="M30.7806 58.9997L28.5789 53.085C28.3678 52.5027 27.855 52.1043 27.2518 52.043L23.8135 51.6446C23.3913 51.5834 23.0294 51.3075 22.9087 50.9091L21.28 46.1897C20.9181 45.1784 19.772 44.7187 18.837 45.209C17.9624 45.6074 17.2988 46.3429 16.9671 47.2316L14.4336 54.3721"
                        stroke="var(--black)" strokeWidth="2.6" strokeLinecap="round"/>
                      <path
                        d="M18.2339 43.6158C19.3499 43.6158 20.2547 42.6965 20.2547 41.5626C20.2547 40.4286 19.3499 39.5093 18.2339 39.5093C17.1179 39.5093 16.2131 40.4286 16.2131 41.5626C16.2131 42.6965 17.1179 43.6158 18.2339 43.6158Z"
                        stroke="var(--black)" strokeWidth="2.6"/>
                      <path d="M19.7117 45.0557L26.2565 44.2896" stroke="var(--black)" strokeWidth="2.6"/>
                      <path
                        d="M26.5053 35.8085C27.2427 35.8085 27.8405 35.2019 27.8405 34.4536C27.8405 33.7053 27.2427 33.0986 26.5053 33.0986C25.7679 33.0986 25.1702 33.7053 25.1702 34.4536C25.1702 35.2019 25.7679 35.8085 26.5053 35.8085Z"
                        stroke="var(--black)" strokeWidth="2.4"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-tyrolienne", defaultMessage: "Tyrolienne" })}
                    </p>
                  </div>
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.14267 15.2188H31.1094V27.4381H9.14267L3 21.3284L9.14267 15.2188Z"
                            stroke="var(--black)" strokeWidth="2.4"/>
                      <path d="M54.0761 13.6081H31.1094V1.38867H54.0761L60.2188 7.49836L54.0761 13.6081Z"
                            stroke="var(--black)" strokeWidth="2.4"/>
                      <path d="M54.0761 41.4894H31.1094V29.27H54.0761L60.2188 35.3797L54.0761 41.4894Z"
                            stroke="var(--black)" strokeWidth="2.4"/>
                      <path d="M31.1094 63V0" stroke="var(--black)" strokeWidth="2.4"/>
                      <path d="M14.958 63H46.958" stroke="var(--black)" strokeWidth="2.4"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-excursion", defaultMessage: "Excursion" })}
                    </p>
                  </div>
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.7914 32.9837L15.0386 43.4309C15.1126 44.0504 14.9868 44.6752 14.6802 45.2115L9.2018 54.7949C8.66069 55.7415 9.2015 56.9581 10.265 57.1868V57.1868C10.8335 57.3091 11.4188 57.108 11.7916 56.6623L19.264 47.728C19.657 47.2582 19.8965 46.6755 19.9501 46.059L20.8026 36.2555"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M26.6815 37.8744C27.5761 38.0611 28.2303 38.8304 28.271 39.7433L28.7262 49.9681C28.7707 50.969 29.5951 51.7575 30.5969 51.7575V51.7575C31.6312 51.7575 32.4696 50.9191 32.4696 49.8849V36.1375C32.4696 35.3262 31.9794 34.5953 31.2288 34.2873L21.3512 30.2342L25.366 20.9709L41.2069 27.5166C42.0728 27.8744 43.0643 27.4575 43.4144 26.5885V26.5885C43.7304 25.8041 43.4164 24.907 42.6802 24.4909L24.6466 14.2992C23.5871 13.7004 22.3317 13.5512 21.1613 13.885V13.885C20.3133 14.1269 19.6345 14.7635 19.3388 15.5943L14.0025 30.5876C13.1893 32.8723 14.5799 35.3491 16.9538 35.8445L26.6815 37.8744Z"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M27.8397 11.8654C30.4634 11.8654 32.5904 9.687 32.5904 6.99983C32.5904 4.31266 30.4634 2.13428 27.8397 2.13428C25.2159 2.13428 23.0889 4.31266 23.0889 6.99983C23.0889 9.687 25.2159 11.8654 27.8397 11.8654Z"
                        stroke="var(--black)" strokeWidth="2.4"/>
                      <path
                        d="M61.0001 3.06934L51.1844 13.3525L47.4451 32.0491L39.0316 37.1906L35.2923 54.9524L14.7261 63.3658"
                        stroke="var(--black)" strokeWidth="2.4" strokeLinecap="round"/>
                      <path
                        d="M19.4002 15.2218L16.3625 14.0591C14.7609 13.4462 12.9723 14.2981 12.4389 15.9279L8.35546 28.4066C7.78636 30.1457 8.89336 31.9847 10.6966 32.2959L13.0489 32.7018"
                        stroke="var(--black)" strokeWidth="2.4"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-escalade", defaultMessage: "Escalade" })}
                    </p>
                  </div>
                </div>
              }
              {!summer &&
                <div className="season-bloc">
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 43.0196L53.4501 61.5192C56.856 62.7681 60.679 61.8295 63.1194 59.1453V59.1453"
                            stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M33.3891 38.6731C34.0442 39.2781 34.2197 40.2436 33.8193 41.0403L29.3551 49.9255C28.7243 51.1811 29.5262 52.6829 30.9205 52.8572V52.8572C31.6286 52.9457 32.3314 52.6566 32.7723 52.0955L41.9316 40.4382C42.5714 39.6239 42.4838 38.4559 41.7297 37.7462L34.5617 30.9998L36.5617 27.4998L47.9424 31.0016C48.858 31.2833 49.8369 30.8169 50.1949 29.9283V29.9283C50.5546 29.0353 50.1668 28.0158 49.3044 27.5877L40.0617 22.9998L42.5764 18.5294C43.6458 16.6283 42.8645 14.2193 40.8829 13.3078V13.3078C39.2209 12.5433 37.2498 13.0958 36.2273 14.6128L27.0614 28.2122C25.9579 29.8494 26.2141 32.0468 27.6645 33.3863L33.3891 38.6731Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M48.1435 11.4095C50.9502 11.4095 53.2255 9.07925 53.2255 6.20475C53.2255 3.33025 50.9502 1 48.1435 1C45.3368 1 43.0615 3.33025 43.0615 6.20475C43.0615 9.07925 45.3368 11.4095 48.1435 11.4095Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path d="M36.5618 27.5L15.5618 19" stroke="var(--black)" strokeWidth="2.3"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-ski", defaultMessage: "Ski" })}
                    </p>
                  </div>
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M32 18C34.7614 18 37 15.3137 37 12C37 8.68629 34.7614 6 32 6C29.2386 6 27 8.68629 27 12C27 15.3137 29.2386 18 32 18Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M32.1 23C35.7 23 38.3 24.1 40.1 27.5C35.2 33.7 37.8 40.6 40.4 45.7C51.1 40.4 58.2 48.3 58.2 48.3C50.8 53.5 41.8 56.5 32.1 56.5C22.4 56.5 13.4 53.5 6 48.3C6 48.3 13.2 40.4 23.9 45.8C26.5 40.8 29.1 33.8 24.2 27.6C25.9 24.2 28.5 23 32.1 23Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M40.1 27.6C45.0001 32.5001 49.0001 41.0002 52.5001 45.0002M9.50007 45.0006C8.90007 49.4006 21.0001 31.5 24.2 27.5"
                        stroke="var(--black)" strokeWidth="2.3"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-yoga", defaultMessage: "Yoga" })}
                    </p>
                  </div>
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M41.022 61.3482H55.1046C58.5717 61.3482 61.6934 59.2481 62.9999 56.0366V56.0366"
                            stroke="var(--black)" strokeWidth="2.3"/>
                      <path d="M15.2173 61.3482H29.2999C32.767 61.3482 35.8887 59.2481 37.1952 56.0366V56.0366"
                            stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M33.376 36.1543L31.5252 47.1993C31.4576 47.603 31.3138 47.9888 31.1016 48.3356L26.3534 56.097C25.779 57.0358 26.3099 58.2785 27.384 58.5095V58.5095C27.9615 58.6337 28.5552 58.4213 28.9226 57.959L34.9889 50.3269C35.2545 49.9927 35.4649 49.6168 35.612 49.2135L38.6325 40.933"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M43.9173 44.2614C44.4497 44.6145 44.7814 45.2008 44.8098 45.8391L45.3006 56.8632C45.3461 57.8864 46.1889 58.6925 47.2132 58.6925V58.6925C48.2705 58.6925 49.1277 57.8354 49.1277 56.778V44.0312C49.1277 43.5371 48.9447 43.0604 48.6142 42.6932L40.6279 33.8208L43.7767 22.4388L59.9719 29.1309C60.8571 29.4967 61.8708 29.0705 62.2287 28.1821V28.1821C62.5518 27.3801 62.2307 26.4629 61.478 26.0376L42.5961 15.3664C41.7977 14.9152 40.8517 14.8027 39.9697 15.0543V15.0543C38.8081 15.3856 37.9067 16.3038 37.5969 17.4714L33.2343 33.9142C32.7945 35.5718 33.4604 37.3254 34.8895 38.2733L43.9173 44.2614Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M44.3941 12.1738C47.0766 12.1738 49.2512 9.94671 49.2512 7.19945C49.2512 4.45219 47.0766 2.2251 44.3941 2.2251C41.7117 2.2251 39.5371 4.45219 39.5371 7.19945C39.5371 9.94671 41.7117 12.1738 44.3941 12.1738Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path d="M61.034 28.8496L54.396 58.6929" stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M16.9548 13.4369C17.2154 14.9262 17.0784 16.9405 16.6714 19.2077C16.2686 21.4522 15.6198 23.8474 14.9274 26.0556C14.236 28.2606 13.5072 30.26 12.9508 31.7089C12.8627 31.9381 12.779 32.1535 12.7006 32.3536C12.5588 32.192 12.407 32.0179 12.2463 31.8322C11.2309 30.6583 9.86631 29.0253 8.46716 27.1862C7.06593 25.3444 5.64231 23.312 4.50109 21.3377C3.34832 19.3434 2.53533 17.4954 2.27469 16.0061C1.71844 12.8278 2.11723 9.84317 3.15319 7.59787C4.19145 5.34759 5.80817 3.94178 7.67107 3.61575C9.53398 3.28971 11.532 4.06289 13.2728 5.8268C15.0098 7.5868 16.3985 10.2586 16.9548 13.4369Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path d="M12.4155 30.7256L13.4041 36.3741" stroke="var(--black)" strokeWidth="2.3"/>
                      <path d="M4.58984 5.41357L16.7579 13.9569" stroke="var(--black)"/>
                      <path d="M11.6504 4.17725L3.1071 16.3453" stroke="var(--black)"/>
                      <path d="M15.8516 8.77832L6.14328 22.6056" stroke="var(--black)"/>
                      <path d="M17.3345 17.2515L9.56785 28.3133" stroke="var(--black)"/>
                      <path d="M2.20068 11.1675L16.028 20.8758" stroke="var(--black)"/>
                      <path d="M3.68408 19.6406L14.7459 27.4073" stroke="var(--black)"/>
                      <path
                        d="M32.9074 15.9332C32.6601 17.4248 31.8638 19.2801 30.729 21.2846C29.6056 23.2691 28.2003 25.3143 26.8157 27.1686C25.4331 29.0202 24.0833 30.6654 23.0785 31.8483C22.9195 32.0355 22.7692 32.211 22.6289 32.3738C22.5486 32.1744 22.463 31.9599 22.3729 31.7314C21.8035 30.2875 21.0567 28.2948 20.3455 26.0961C19.6333 23.8942 18.963 21.505 18.54 19.2642C18.1127 17.0007 17.9575 14.9877 18.2048 13.4961C18.7324 10.3129 20.0971 7.6288 21.8181 5.85324C23.543 4.07376 25.534 3.28265 27.3998 3.59192C29.2656 3.90119 30.8949 5.2924 31.9533 7.53326C33.0094 9.76915 33.435 12.75 32.9074 15.9332Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path d="M22.8994 30.7437L21.9617 36.4009" stroke="var(--black)" strokeWidth="2.3"/>
                      <path d="M23.897 4.26758L32.5493 16.3583" stroke="var(--black)"/>
                      <path d="M30.9687 5.43994L18.878 14.0923" stroke="var(--black)"/>
                      <path d="M33.4092 11.1724L19.6697 21.0046" stroke="var(--black)"/>
                      <path d="M32.0024 19.6582L21.0109 27.524" stroke="var(--black)"/>
                      <path d="M19.7373 8.90625L29.5695 22.6457" stroke="var(--black)"/>
                      <path d="M18.3311 17.3921L26.1968 28.3837" stroke="var(--black)"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-raquette", defaultMessage: "Raquette" })}
                    </p>
                  </div>
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 62.8613H49.2033C52.831 62.8613 56.0972 60.6639 57.4643 57.3037V57.3037"
                            stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M26.4683 36.5L24.5318 48.0566C24.461 48.479 24.3105 48.8826 24.0885 49.2455L19.1204 57.3664C18.5194 58.3486 19.0749 59.649 20.1988 59.8906V59.8906C20.803 60.0206 21.4242 59.7983 21.8087 59.3147L28.1744 51.3057C28.4401 50.9715 28.6505 50.5955 28.7976 50.1922L31.9683 41.5"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M37.5367 45.009C38.0691 45.3622 38.4008 45.9485 38.4292 46.5867L38.9448 58.1684C38.9924 59.2391 39.8743 60.0825 40.946 60.0825V60.0825C42.0523 60.0825 42.9491 59.1857 42.9491 58.0794V44.7066C42.9491 44.2124 42.7662 43.7358 42.4356 43.3685L34.0557 34.0588L37.3503 22.1496L54.2956 29.1517C55.2218 29.5344 56.2825 29.0885 56.657 28.1589V28.1589C56.995 27.3198 56.6591 26.3602 55.8715 25.9151L36.1151 14.7497C35.2797 14.2775 34.2898 14.1599 33.367 14.4231V14.4231C32.1516 14.7697 31.2084 15.7305 30.8843 16.9521L26.2856 34.2849C25.8458 35.9426 26.5116 37.6961 27.9408 38.6441L37.5367 45.009Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M37.9965 11.4095C40.8032 11.4095 43.0785 9.07925 43.0785 6.20475C43.0785 3.33025 40.8032 1 37.9965 1C35.1898 1 32.9146 3.33025 32.9146 6.20475C32.9146 9.07925 35.1898 11.4095 37.9965 11.4095Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path d="M55.4074 28.8574L48.4619 60.083" stroke="var(--black)" strokeWidth="2.3"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-ski-randonnee", defaultMessage: "Ski de randonnée" })}
                    </p>
                  </div>
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M47.502 13.4C50.65 13.4 53.202 10.848 53.202 7.7C53.202 4.55198 50.65 2 47.502 2C44.354 2 41.802 4.55198 41.802 7.7C41.802 10.848 44.354 13.4 47.502 13.4Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M59.8495 28.897C59.8229 27.8336 58.9685 26.9771 57.9052 26.9478L50.1828 26.7352C49.4022 26.7138 48.7055 26.2399 48.3987 25.5218L44.9956 17.5572C44.4866 16.3658 43.535 15.4186 42.3413 14.915L39.337 13.6476C38.3407 13.2272 37.2349 13.1412 36.1855 13.4025L15.171 18.6347C14.1277 18.8945 13.4764 19.9327 13.6967 20.9851L13.806 21.5077C14.0269 22.563 15.0446 23.2533 16.1068 23.0683L32.0021 20.3L24.8021 33.7L19.8021 40.8L9.32438 41.0346C7.91819 41.0661 6.98293 42.5008 7.52166 43.8001L7.60707 44.0061C7.90837 44.7328 8.60767 45.2153 9.39397 45.2391L23.5879 45.6693C24.2253 45.6886 24.8338 45.4027 25.2259 44.8997L29.9021 38.9L37.7021 47.6L36.4061 56.3482C36.24 57.4691 37.0374 58.5039 38.1636 58.6291L38.8371 58.7039C39.8439 58.8158 40.7754 58.1571 41.0056 57.1706L43.8647 44.9173C44.0134 44.2799 43.8409 43.6102 43.4028 43.124L35.9021 34.8L41.7021 24.8L45.9516 31.0442C46.3541 31.6355 47.0417 31.9669 47.755 31.9133L58.0051 31.1426C59.0678 31.0627 59.8812 30.1636 59.8546 29.0983L59.8495 28.897Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path d="M48.4021 57.7998C47.6021 60.8998 44.9021 62.9998 41.7021 62.9998H32.1021"
                            stroke="var(--black)" strokeWidth="2.3"/>
                      <path d="M9.60397 54.5328C6.48502 53.8101 4.31849 51.1632 4.23887 47.9642L4 38.3672"
                            stroke="var(--black)" strokeWidth="2.3"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-patin", defaultMessage: "Patin à glace" })}
                    </p>
                  </div>
                  <div>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.999848 31.7668L49.5825 54.5673C52.9581 56.1516 56.9348 55.726 59.8988 53.4635L62.682 51.3389"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M18.7057 24.5981C18.424 24.5134 18.1644 24.3677 17.9454 24.1712L4.33498 11.9584C3.30215 11.0316 1.65487 11.6897 1.54479 13.073V13.073C1.51194 13.4858 1.63313 13.8963 1.88499 14.2251L13.3844 29.2382C13.6062 29.5278 13.9031 29.7513 14.2428 29.8843L36.6501 38.6599L47.4765 45.6805L61.1916 46.8851C61.9807 46.9544 62.7077 46.4536 62.9242 45.6917V45.6917C63.1885 44.7614 62.6002 43.8043 61.6509 43.6198L48.7728 41.1177L41.0205 31.9751C40.5207 31.3856 39.8607 30.9535 39.1205 30.7311L18.7057 24.5981Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                      <path
                        d="M50.2641 34.7484C53.0708 34.7484 55.3461 32.4181 55.3461 29.5436C55.3461 26.6691 53.0708 24.3389 50.2641 24.3389C47.4574 24.3389 45.1821 26.6691 45.1821 29.5436C45.1821 32.4181 47.4574 34.7484 50.2641 34.7484Z"
                        stroke="var(--black)" strokeWidth="2.3"/>
                    </svg>
                    <p>
                      {intl.formatMessage({ id: "champex-activity-luge", defaultMessage: "Luge" })}
                    </p>
                  </div>
                </div>
              }
            </Textbox>
          </div>
        </TwoColumn>
      </Section>
    </>
  );
};

export default Champex;
