import React, { FormEvent, useEffect, useReducer, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { classNames, useAppConfig, useIsMounted } from "@ct-react/core";
import { buildEmptyFormData, contactFormReducer } from "./form-data";
import { FormButton } from "../common";
import Loader from "../loader";
import "./form.scss";

const EMAIL_MUTATION = gql`
  mutation SendMail($merchantId: ID!, $request: MailRequestInput!) {
    mailRelatedContact(merchantId: $merchantId, request: $request)
  }
`;

const transDefs = defineMessages({
  name: { id: "form-label-nom", defaultMessage: "Nom" },
  surname: { id: "form-label-prenom", defaultMessage: "Prénom" },
  email: { id: "form-label-email", defaultMessage: "Email" },
  tel: { id: "form-label-telephone", defaultMessage: "Téléphone" },
  message: { id: "form-label-message", defaultMessage: "Message" },
  submitter: { id: "btn-send", defaultMessage: "Envoyer" },
  error: { id: "form-span-field-not-valid", defaultMessage: "* Champ non valide" },
  successTitle: { id: "form-callback-email-received", defaultMessage: "Nous avons bien reçu votre e-mail !" },
  successDetail: { id: "form-callback-will-reply-asap", defaultMessage: "Nous vous répondrons dès que possible." },
  failTitle: { id: "form-callback-email-send-failed", defaultMessage: "Echec de l'envoie !" },
  failDetail: { id: "form-callback-issue-occured", defaultMessage: "Un problème est survenue." }
});

const ContactForm = () => {

  const intl = useIntl();
  const isMounted = useIsMounted();
  const { options: { merchantId } } = useAppConfig();

  const [ formData, dispatchFormData ] = useReducer(contactFormReducer, buildEmptyFormData());
  const [ touchedForm, setTouchedForm ] = useState<boolean>(false);
  const [ isDisabled, setIsDisabled ] = useState<boolean>(true);
  const [ succeeded, setSucceeded ] = useState<boolean | undefined>(undefined);

  // check form validity on changes
  useEffect(() => {
    if (!isMounted) return;
    const validationValues = Object.entries(formData).filter(([ key, _ ]) => /Valid$/g.test(key)).map(([ _, value ]) => value);
    setIsDisabled(validationValues.includes(false));
  }, [ formData ]);

  const [ sendEmail, { loading } ] = useMutation(EMAIL_MUTATION, { variables: { merchantId } });

  const onFormDataChange = (e: FormEvent) => {
    // @ts-ignore
    const { name: type, value } = e.target;
    dispatchFormData({ type, value });
    !touchedForm && setTouchedForm(true);
  };

  const onSubmit = () => sendEmail({
    variables: {
      merchantId,
      request: {
        body: formData.message,
        contact: {
          firstName: formData.surname,
          lastName: formData.name,
          email: formData.email,
          phone: formData.tel
        }
      }
    }
  })
  .then(() => setSucceeded(true))
  .catch(() => setSucceeded(false));

  const onDoneCallback = () => {
    succeeded && dispatchFormData({ type: "reset", value: null });
    setSucceeded(undefined);
    setTouchedForm(false);
  }

  return (
    <>
      {loading && <Loader />}
      {typeof succeeded === "boolean" &&
        <FormCallback state={succeeded} onModalClosed={onDoneCallback} />
      }
      <form className="contactForm">

        <div className="input-block">
          <label htmlFor="name">{`${intl.formatMessage(transDefs.name)}*`}</label>
          <input type="text" name="name" id="name" required
                 value={formData.name} onChange={onFormDataChange} />
          <span className="error">
            {(touchedForm && !formData.nameValid) && intl.formatMessage(transDefs.error)}
          </span>
        </div>

        <div className="input-block">
          <label htmlFor="surname">{`${intl.formatMessage(transDefs.surname)}*`}</label>
          <input type="text" name="surname" id="surname" required
                 value={formData.surname} onChange={onFormDataChange} />
          <span className="error">
            {(touchedForm && !formData.surnameValid) && intl.formatMessage(transDefs.error)}
          </span>
        </div>

        <div className="input-block">
          <label htmlFor="email">{`${intl.formatMessage(transDefs.email)}*`}</label>
          <input type="email" name="email" id="email" required
                 value={formData.email} onChange={onFormDataChange} />
          <span className="error">
            {(touchedForm && !formData.emailValid) && intl.formatMessage(transDefs.error)}
          </span>
        </div>

        <div className="input-block">
          <label htmlFor="tel">{`${intl.formatMessage(transDefs.tel)}*`}</label>
          <input type="tel" name="tel" id="tel" required
                 value={formData.tel} onChange={onFormDataChange} />
          <span className="error">
            {(touchedForm && !formData.telValid) && intl.formatMessage(transDefs.error)}
          </span>
        </div>

        <div className="textarea-block">
          <label htmlFor="message">{`${intl.formatMessage(transDefs.message)}*`}</label>
          <textarea name="message" id="message" cols={30} rows={10} required
                    value={formData.message} onChange={onFormDataChange}></textarea>
          <span className="error">
            {(touchedForm && !formData.messageValid) && intl.formatMessage(transDefs.error)}
          </span>
        </div>

        <FormButton value={intl.formatMessage(transDefs.submitter)} disabled={isDisabled}
                    onSubmit={onSubmit} />

      </form>
    </>
  );
};

type FormCallbackProps = {
  state: boolean;
  onModalClosed: () => void
}

const FormCallback = ({ state, onModalClosed }: FormCallbackProps) => {

  const intl = useIntl();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => { document.body.style.overflow = "initial"; }
  }, []);

  return (
    <div className="form-result">
      <div className={classNames({ succeeded: state })}>
        <FontAwesomeIcon icon={state ? faCheck : faXmark} />
      </div>
      <h3>{intl.formatMessage(state ?transDefs.successTitle : transDefs.failTitle)}</h3>
      <p>{intl.formatMessage(state ? transDefs.successDetail : transDefs.failDetail)}</p>
      <FontAwesomeIcon icon={faXmark}
                       className="close-form-callback"
                       onClick={() => onModalClosed()} />
    </div>);

}
export default ContactForm;
