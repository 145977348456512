import { useEffect, useState } from "react";

const defaultQuery = "(max-width: 900px)";

const initialValue = (query: string) => {
  if (typeof window !== "undefined" && "matchMedia" in window)
    return window.matchMedia(query).matches;
  return false;
}

// warning: return false by default on SSR

export const useCheckMobileScreen = (query?: string) => {

  const [ matches, setMatches ] = useState<boolean>(initialValue(query || defaultQuery));

  useEffect(() => {
    if ("matchMedia" in window) {
      const checker = window.matchMedia(query || defaultQuery);
      const setter = (e: MediaQueryListEvent) => setMatches(e.matches);
      setMatches(checker.matches);
      checker.addEventListener("change", setter);
      return () => checker.removeEventListener("change", setter);
    }
  }, [ query ]);

  return matches;

}
