import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { Splide, SplideSlide, SplideTrack, Options } from "@splidejs/react-splide";
import { classNames } from "@ct-react/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@apollo/client";
import CardArticle from "./card";
import { Asset, Image } from "../models/image";
import { SEARCH_GQL_DATA } from "../pages/articles/articles";
import "./carousel.scss";
import { ArticleSummary } from "../models/article";

type ArticleInfoCarouselProps = {
  isActive: boolean,
  setIsActive: Dispatch<SetStateAction<boolean>>,
  images: Image[],
  onClick: () => void
};

const defineSourceSet = (assets: Asset[]): string | undefined => {
  if (assets.every(a => !a.breakpoint))
    return undefined;

  const sm = { size: "400w", url: assets.find(a => a.breakpoint === "SM")?.url };
  const md = { size: "800w", url: assets.find(a => a.breakpoint === "MD")?.url };
  const lg = { size: "1200w", url: assets.find(a => a.breakpoint === "LG")?.url };
  return [ sm, md, lg ].filter(({ url }) => !!url).map(({ size, url }) => `${url} ${size}`).join(", ");
}

export const ArticleInfoCarousel = (
  {
    isActive,
    setIsActive,
    images,
    onClick
  }: ArticleInfoCarouselProps) => {

  const [ currentImgNumber, setCurrentImgNumber ] = useState<number>(1);

  const carouselRef = useRef<Splide>(null);
  const splideOptions = useMemo((): Options => ({
    type: "loop",
    arrows: false,
    pagination: false,
    mediaQuery: 'min',
    start: 0,
    perPage: 1,
    perMove: 1,
    height: "40vh",
    cover: true,
    breakpoints: {
      992: { cover: false, height: "40vw", arrows: true, keyboard: true }
    }
  }), []);

  const handleImageChange = () => {
    if (!carouselRef.current) return;
    const nbrOfImg = carouselRef.current.splide?.index;
    nbrOfImg != undefined && setCurrentImgNumber(nbrOfImg + 1);
  };

  const wrapperClasses = classNames("article-image-carousel-block", { show: isActive });

  return (
    <div className={wrapperClasses}
         onClick={() => onClick()}>
      <Splide ref={carouselRef}
              className="article-image-carousel"
              options={splideOptions}
              onMove={handleImageChange}>
        {images.map((img: any, i) => (
          <SplideSlide key={i}>
            <img srcSet={defineSourceSet(img.assets)}
                 src={img.assets[0].url}
                 sizes="(max-width: 576px) 800w, 1200w"
                 alt={img.alternativeText?.value} />
          </SplideSlide>
        ))}
      </Splide>
      <FontAwesomeIcon className="article-image-carousel-close"
                       size="2x"
                       icon={faXmark}
                       onClick={() => { setIsActive(false); onClick(); }} />
      <div className="article-image-carousel-current">{`${currentImgNumber} / ${images.length}`}</div>
    </div>
  );
};

export const ArticleCarouselSuggestions = () => {

  const slideOptions = useMemo((): Options => ({
    type: "loop",
    breakpoints: {
      992: { destroy: true, },
      1050: { perPage: 2 },
      1450: { perPage: 2 }
    },
    perPage: 3,
    perMove: 1,
    autoplay: true,
    pauseOnHover: true,
    speed: 800,
    interval: 2500,
    start: 0,
    gap: "100px",
    padding: "16px",
    pagination: false,
    rewind: true,
    arrowPath: "M39.56,18.94L27.4,6.78c-0.58-0.58-1.53-0.58-2.12,0c-0.58,0.58-0.58,1.53,0,2.12l9.61,9.61H1.5C0.67,18.5,0,19.17,0,20 s0.67,1.5,1.5,1.5h33.39l-9.61,9.61c-0.58,0.58-0.58,1.53,0,2.2c0.29,0.29,0.68,0.44,1.06,0.44s0.77-0.15,1.06-0.44l12.16-12.16 C39.84,20.78,40,20.4,40,20C40,19.6,39.84,19.22,39.56,18.94z",
    classes: {
      arrows: 'splide__arrows carousel-arrowsBlock',
      arrow: 'splide__arrow carousel-arrow',
      prev: 'splide__arrow--prev carousel-arrowPrev',
      next: 'splide__arrow--next carousel-arrowNext',
      pagination: "splide__pagination carousel-pagination",
    }
  }), []);

  const { data } = useQuery(SEARCH_GQL_DATA, {
    variables: {
      where: { saleAccommodation: {} },
      first: 6
    },
    ssr: true
  });

  if (!data?.search)
    return null;

  return (
    <Splide className="articlesCarousel"
            hasTrack={false}
            aria-label="Images"
            options={slideOptions}>
      <SplideTrack>
        {data.search.edges.map((edge: { node: ArticleSummary }, i: number) => (
          <SplideSlide key={i}>
            <CardArticle data={edge.node} />
          </SplideSlide>)
        )}
      </SplideTrack>
    </Splide>);

};
