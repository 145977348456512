import loadable from "@loadable/component";

loadable.lib(
  () => import(/* webpackChunkName: "map-implement" */"@ct-react/map"),
  { ssr: false });

loadable(
  // @ts-ignore
  () => import(/* webpackChunkName: "map-implement" */"./map.scss"),
  { ssr: false });

export const MapContainer = loadable(
  () => import(/* webpackChunkName: "map-implement" */"@ct-react/map"),
  { ssr: false });

export const MapMarker = loadable(
  () => import(/* webpackChunkName: "map-implement" */"@ct-react/map"),
  { ssr: false, resolveComponent: m => m.Marker });

export const MapPopup = loadable(
  () => import(/* webpackChunkName: "map-implement" */"@ct-react/map"),
  { ssr: false, resolveComponent: m => m.Popup });

export const MapAsyncPopupMarker = loadable(
  () => import(/* webpackChunkName: "map-implement" */"./async-popup-marker"),
  { ssr: false });
