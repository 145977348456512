import React, { useEffect, useRef, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { AppConfig, AppConfigProvider, useIsMounted } from "@ct-react/core";
import { defineLocale, LocaleProvider, useLocaleContext } from "@ct-react/locale";
import { APOLLO_CACHE_SCRIPT_ID, APP_CTX_SCRIPT_ID } from "@shared/constants";
import { handleMomentJsLocale } from "@shared/locale";
import buildApolloClient from "@shared/gql/client";
import App from "./app/app";

const loadingLocale = defineLocale(navigator.languages || [], window.location.pathname);

const Wrapper = () => {

  const isMounted = useIsMounted();
  const { locale } = useLocaleContext();

  const ctx = useRef<AppConfig>(window.__APP_CTX__);
  const [ apolloClient, setApolloClient ] = useState(buildApolloClient(ctx.current, false, locale, window.__APOLLO_STATE__));

  useEffect(() => {
    if (!isMounted) return;
    setApolloClient(buildApolloClient(ctx.current, false, locale));
  }, [ locale ]);

  useEffect(() => {
    if (isMounted) return;
    delete (window as Partial<Window>).__APP_CTX__;
    const stateScriptTag = document.getElementById(APP_CTX_SCRIPT_ID);
    !!stateScriptTag && stateScriptTag.parentNode?.removeChild(stateScriptTag);
  }, [ ctx ]);

  useEffect(() => {
    if (isMounted) return;
    delete (window as Partial<Window>).__APOLLO_STATE__;
    const stateScriptTag = document.getElementById(APOLLO_CACHE_SCRIPT_ID);
    !!stateScriptTag && stateScriptTag.parentNode?.removeChild(stateScriptTag);
  }, [ apolloClient ]);

  return (
    <ApolloProvider client={apolloClient}>
      <AppConfigProvider value={ctx.current}>
        <HelmetProvider>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </HelmetProvider>
      </AppConfigProvider>
    </ApolloProvider>);

}

loadableReady()
.then(() => handleMomentJsLocale(loadingLocale, true))
.then(() => hydrateRoot(
  document.getElementById("app")!,
  <LocaleProvider locale={loadingLocale} beforeLocaleChange={locale => handleMomentJsLocale(locale, true)}>
    <Wrapper/>
  </LocaleProvider>));
