import React, { PropsWithChildren, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { classNames } from "@ct-react/core";
import "./section.scss"

type HeaderProps = PropsWithChildren & {
  subtitle: string,
  img: string,
}

type SectionProps = PropsWithChildren & {
  id?: string,
  className?: string,
  background?: string,
  color?: string
}

export const Header = (
  {
    subtitle,
    img,
    children
  }: HeaderProps) => {

  const headers = useRef<HTMLDivElement>(null);
  const imageStyle = `
    linear-gradient( to bottom,rgba(0,0,0,0.3), rgba(0,0,0,0) 20%),
    url(${img})
  `;

  useEffect(() => {
    gsap.to(headers.current, {
      autoAlpha: 1,
      y: 0,
      duration: 0.8
    });
  }, []);

  return (
    <section className="page-header"
             style={{ backgroundImage: imageStyle }}>
      <div ref={headers}
           className="headers">
        <h4>{subtitle}</h4>
        <h1>{children}</h1>
      </div>
    </section>);

}

export const Section = (
  {
    id,
    children,
    className,
    background = "#FFF",
    color = "var(--primary)"
  }: SectionProps) => (
  <section {...{ ...!!id && { id } }}
           className={classNames("section", className)}
           style={{ backgroundColor: background, color }}>
    {children}
  </section>
);
