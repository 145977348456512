import { gql } from "@apollo/client";

const FULL_IMAGE_FRAGMENT = gql`
  fragment FullImageFields on Image {
    main
    assets { url breakpoint }
    title { value }
    alternativeText { value }
  }
`;

export const ARTICLE_SUMMARY_FRAGMENT = gql`
  ${FULL_IMAGE_FRAGMENT}
  fragment ArticleSummaryFields on SaleAccommodationArticle {
    id
    title { value }
    images { ...FullImageFields }
    features { rooms livingSpace parkings bathrooms bedrooms }
    address { city }
    price { onDemand value }
    sold
  }
`;

export const ARTICLE_DETAIL_FRAGMENT = gql`
  ${FULL_IMAGE_FRAGMENT}
  fragment ArticleDetailFields on SaleAccommodationArticle {
    id
    title { value }
    description { value }
    images { ...FullImageFields }
    coordinates
    address { city }
    price { onDemand value }
    sold
    features { rooms bedrooms bathrooms withParking isFurnished charges livingSpace orientation restrooms heatings isSellableToForeigners renovationFund }
  }
`;

export const PAGINATION_INFO_FRAGMENT = gql`
  fragment PaginationFields on SearchPaginationInfo {
    currentPage
    pageSize
    pageCount
    previousPageCursor
    nextPageCursor
    lastPageCursor
    linkPageCursors { page cursor }
  }
`;
