import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { AppConfig } from "@ct-react/core";
import { Locale } from "@ct-react/locale";
import { cachePolicies } from "@shared/gql/policies";

const buildConsumerMiddleWare = (channelId: string, locale?: Locale): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "GQL-Consumer-Channel": channelId,
        ...(!!locale) && { "GQL-Consumer-Lang": locale.basename }
      }
    }));
    return forward(operation);
  });
}

const buildApolloClient = (ctx: AppConfig, ssrMode: boolean = false, locale?: Locale, restoringCache?: NormalizedCacheObject) => {
  const cache = new InMemoryCache({ ...cachePolicies });
  if (!!restoringCache) cache.restore(restoringCache);

  return new ApolloClient({
    ssrMode,
    connectToDevTools: !ssrMode && ctx.env === "development",
    link: from([
      onError(e => console.error("gql error", e )),
      buildConsumerMiddleWare(ctx.gqlChannelId, locale),
      createHttpLink({ uri: ctx.gqlClientUrl, fetch })
    ]),
    cache
  } as unknown as ApolloClientOptions<InMemoryCache>);
}

export default buildApolloClient;
