import { InMemoryCacheConfig } from "@apollo/client";

export const cachePolicies: Partial<InMemoryCacheConfig> = ({
  possibleTypes: {
    AnyArticle: [
      "RentalAccommodationArticle",
      "SeasonalAccommodationArticle",
      "AnnualAccommodationArticle",
      "SaleAccommodationArticle"
    ]
  },
  typePolicies: {
    AnyArticle: {
      fields: {
        features: {
          merge: (existing, incoming) => ({ ...existing, ...incoming })
        }
      }
    }
  }
});
