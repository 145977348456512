import React, { useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { defineMessages, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import parse from 'html-react-parser';
import { classNames } from "@ct-react/core";
import { useLocaleFormatter } from "@ct-react/locale";
import { ARTICLE_DETAIL_FRAGMENT } from "@shared/gql/fragments";
import { SkeletonableProps } from "../../utils/components";
import { ArticleEquipments } from "../../models/article";
import { Image } from "../../models/image";
import { MapContainer, MapMarker } from "../../bundles/map-implement";
import { Section } from "../../components/wrapper/section";
import { OneColumn, TwoColumn } from "../../components/wrapper/column";
import { CustomButton } from "../../components/common";
import { ArticleInfoCarousel, ArticleCarouselSuggestions } from "../../components/carousel";
import ContactForm from "../../components/contact-form/form";
import MapMarkerSvg from "../../assets/componable-svgs/map-marker.svg";
import "./articleInfo.scss";

const transDefs = defineMessages({
  back: { id: "btn-back", defaultMessage: "Retour" },
  rooms: { id:"rooms",  defaultMessage: " Pièces" },
  sold: { id:"sold", defaultMessage : "Vendu" },
  moreImg: { id:"carousel-see-photos", defaultMessage: "Voir les {length} photos" },
  noImage: { id:"no-image", defaultMessage: "Aucune image disponible" },
  desc: { id:"description", defaultMessage: "Description {break} du bien" },
  noDesc: {  id:"no-description", defaultMessage: "Ce bien ne possède pas de description pour le moment. Pour plus d'informations veuillez nous contacter." },
  contactForm: { id:"articleInfo-interest-in-the-accomodation", defaultMessage: "Formulaire de contact" },
  price: { id:"price", defaultMessage: "Prix" },
  bedrooms: { id:"bedroom", defaultMessage: "Chambres" },
  bathrooms: { id:"bathroom", defaultMessage: "Salles de bain" },
  parking: { id:"parking", defaultMessage: "Parking" },
  furnished: { id:"furniture", defaultMessage: "Meublé" },
  charges: { id:"charges", defaultMessage: "Charges" },
  surface: { id:"surface", defaultMessage: "Surface" },
  orientation: { id:"orientation", defaultMessage: "Orientation" },
  waterCloset: { id:"wc", defaultMessage: "WC" },
  heater: { id:"heater", defaultMessage: "Chauffage" },
  foreigners: { id:"sale-stranger", defaultMessage: "Vente aux étrangers" },
  funds: { id:"renovation-funds", defaultMessage: "Fonds de rénovation" },
  onDemand: { id:"price-on-request-only", defaultMessage: "Prix sur demande" },
  yes: { id:"yes", defaultMessage: "Oui" },
  no: { id:"no", defaultMessage: "Non" },
  more: { id: "btn-see-all-article", defaultMessage: "Voir tous les biens" }
});

const DETAIL_GQL_DATA = gql`
  ${ARTICLE_DETAIL_FRAGMENT}
  query ArticleDetail($articleId: ID!) {
    article: articleDetail(articleId: $articleId) {
      ... on SaleAccommodationArticle { ...ArticleDetailFields }
    }
  }
`;

const ArticleInfo = () => {

  const intl = useIntl();
  const { id: articleId } = useParams();
  const navigate = useNavigate();
  const { print } = useLocaleFormatter();

  const { loading, data } = useQuery(DETAIL_GQL_DATA, { variables: { articleId } });

  const equipments = useMemo(() => {
    if (!data?.article) return undefined;
    return {
      price: data.article.price,
      ...data.article.features,
    }
  }, [ data ]);

  // rendering

  const renderTitle = useMemo(() => {
    if (loading || !data?.article) return <Skeleton />;
    const { rooms, livingSpace } = data.article.features;
    return `${data.article.title.value} | ` +
      `${!!rooms ? `${rooms} ${intl.formatMessage(transDefs.rooms)} | ` : ""}` +
      `${!!livingSpace ? `${livingSpace} m² | ` : ""}` + data.article.address.city;
  },[ data, loading ])

  const renderPrice = useMemo(() => {
    if (loading || !data?.article) return <Skeleton/>;
    if (!data.article.price.onDemand)
      return (<span>{print.price(data.article.price.value, { trailingZeroDisplay: "stripIfInteger" })}</span>);
    return (<span>{intl.formatMessage(transDefs.onDemand)}</span>)
  },[ data, loading ]);

  const renderDescription = useMemo(() => {
    if (loading || !data?.article) return <Skeleton />;
    if (!data.article.description) return intl.formatMessage(transDefs.noDesc);
    return parse(data.article.description.value);
  }, [ data, loading ]);

  return(
    <>
      <section className="article-section">
        <header className="article-header">
          <div onClick={()=>navigate(-1)}>
            <FontAwesomeIcon className="article-header-chevronLeft" icon={faChevronLeft} color="var(--primary)"/>
            <span className="article-header-navigator">{intl.formatMessage(transDefs.back)}</span>
          </div>
          <h1 className="article-header-h1">{renderTitle}</h1>
          <div className="article-header-info">
            <div className="article-header-price">{renderPrice}</div>
            <div className="article-header-icones">
              <div className="article-header-icone" data-gloss="Imprimer en PDF">
                <FontAwesomeIcon icon={faDownload} size="1x" onClick={()=>window.print()}/>
              </div>
            </div>
          </div>
        </header>

        <div className="article-image">
          {data?.article.features.isSold &&
            <div className="chip-sale">{intl.formatMessage(transDefs.sold)}</div>
          }
          <ArticleImageGrid loading={loading} data={data?.article?.images} />
        </div>

        <Section id={"articleInfo"}>
          <TwoColumn>
            <div className="left-box">
              <h1 className="article-info-h1">{renderTitle}</h1>
              <div className="article-info-price">{renderPrice}</div>
              <div className="article-description">
                <h2>{intl.formatMessage(transDefs.desc, { break: <br/> })}</h2>
                <div className="article-description-content">{renderDescription}</div>
                <ArticleEquipmentBox loading={loading} data={equipments} />
              </div>
            </div>
            <div className="right-box">
              <article className="article-form">
                <h2>{intl.formatMessage(transDefs.contactForm)}</h2>
                <ContactForm />
              </article>
            </div>
          </TwoColumn>
        </Section>
      </section>

      <Section id={"article-location"}>
        {(loading || !data?.article)
          ? <Skeleton className="article-map"/>
          : !!data.article.coordinates && <div className="article-map">
            <MapContainer center={data.article.coordinates} zoom={11}>
              <MapMarker position={data.article.coordinates} icon={<MapMarkerSvg />} />
            </MapContainer>
          </div>
        }
      </Section>

      <Section id="article-carousel">
        <OneColumn title={intl.formatMessage({id: "more-objects", defaultMessage: "Autres logements {break} À vendre"},{break: <br/>})}>
          <ArticleCarouselSuggestions />
          <CustomButton to="nos-biens">{intl.formatMessage(transDefs.more)}</CustomButton>
        </OneColumn>
      </Section>

    </>);

}

const ArticleImageGrid = (
  {
    loading,
    data
  }: SkeletonableProps<Image[]>) => {

  const intl = useIntl();
  const [ withCarousel, setWithCarousel ] = useState<boolean>(false);

  const toggleCarousel = () => setWithCarousel(prev => !prev);

  const wrapperClasses = classNames("article-image-grid", { empty: !loading && data?.length === 0 });

  if (loading || !data)
    return (
      <div className={wrapperClasses}>
        {Array.from({ length: 5 }).map((_, i) => <Skeleton key={i} containerClassName="img-skeleton" />)}
      </div>);

  if (data.length === 0)
    return (
      <div className={wrapperClasses}>
        <FontAwesomeIcon icon={faCamera} />
        <p>{intl.formatMessage(transDefs.noImage)}</p>
      </div>);

  return (
    <>
      <div className={wrapperClasses}>
        {Array.from({ length: Math.min(data.length, 5) }).map((_, i) =>
          <div key={i} style={{ backgroundImage: `url(${data[i].assets[0].url})` }}/>
        )}
        <div className="grid-btn"
             onClick={toggleCarousel}>
          <FontAwesomeIcon icon={faCamera}/>
          {intl.formatMessage(transDefs.moreImg, { length: data.length })}
        </div>
      </div>
      <ArticleInfoCarousel isActive={withCarousel}
                           setIsActive={setWithCarousel}
                           images={data}
                           onClick={() => window.innerWidth < 960 && toggleCarousel()} />
    </>);
}

const ArticleEquipmentBox = (
  {
    loading,
    data
  }: SkeletonableProps<ArticleEquipments>) => {

  const intl = useIntl();
  const { print, transLib } = useLocaleFormatter();
  const wrapperClasses = classNames("article-equipment");

  if (loading || !data)
    return null;

  return (
    <div className={wrapperClasses}>
      <ul>
        <li>
          <b>{intl.formatMessage(transDefs.price)}</b>
          <b><span>:</span></b>
          {data.price.onDemand
            ? <p>{intl.formatMessage(transDefs.onDemand)}</p>
            : <p>{print.price(data.price.value, { trailingZeroDisplay: "stripIfInteger" })}</p>
          }
        </li>
        <li>
          <b>{intl.formatMessage(transDefs.bedrooms)}</b>
          <b><span>:</span></b>
          {data.bedrooms || "-"}
        </li>
        <li>
          <b>{intl.formatMessage(transDefs.bathrooms)}</b>
          <b><span>:</span></b>
          {data.bathrooms || "-"}
        </li>
        <li>
          <b>{intl.formatMessage(transDefs.parking)}</b>
          <b><span>:</span></b>
          {intl.formatMessage(data.withParking ? transDefs.yes : transDefs.no)}
        </li>
        <li>
          <b>{intl.formatMessage(transDefs.furnished)}</b>
          <b><span>:</span></b>
          {intl.formatMessage(data.isFurnished ? transDefs.yes : transDefs.no)}
        </li>
        <li>
          <b>{intl.formatMessage(transDefs.charges)}</b>
          <b><span>:</span></b>
          {!data.charges ? "-" : print.price(data.charges)}
        </li>
      </ul>
      <ul>
        <li>
          <b>{intl.formatMessage(transDefs.surface)}</b>
          <b><span>:</span></b>
          {!data.livingSpace ? "-" : `${data.livingSpace} m²`}
        </li>
        <li>
          <b>{intl.formatMessage(transDefs.orientation)}</b>
          <b><span>:</span></b>
          {transLib.match("orientations", data.orientation)}
        </li>
        <li>
          <b>{intl.formatMessage(transDefs.waterCloset)}</b>
          <b><span>:</span></b>
          {data.restrooms || "-"}
        </li>
        <li>
          <b>{intl.formatMessage(transDefs.heater)}</b>
          <b><span>:</span></b>
          {!data.heatings ? "-" : data.heatings.map(h => transLib.match("heatingSystems", h)).join(", ")}
        </li>
        <li>
          <b>{intl.formatMessage(transDefs.foreigners)}</b>
          <b><span>:</span></b>
          {intl.formatMessage(data.isSellableToForeigners ? transDefs.yes : transDefs.no)}
        </li>
        <li>
          <b>{intl.formatMessage(transDefs.funds)}</b>
          <b><span>:</span></b>
          {!data.renovationFund ? intl.formatMessage(transDefs.no) : print.price(data.renovationFund)}
        </li>
      </ul>
    </div>);

}

export default ArticleInfo;
