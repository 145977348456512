import React, { useCallback, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import { classNames, useIsMounted } from "@ct-react/core";
import { LocaleNavLink, useLocaleContext } from "@ct-react/locale";
import Language from "./language";
import LogoSvg from "../../../assets/componable-svgs/logo-aci.svg";
import "./menu.scss";

type BurgerProps = {
  isDown: boolean;
  onChange?: (isDown: boolean) => void;
};

const Burger = (
  {
    isDown,
    onChange = () => void 0
  }: BurgerProps) => {

  const wrapperRef = useRef(null);
  const firstLineRef = useRef(null);
  const secondLineRef = useRef(null);
  const thirdLineRef = useRef(null);

  const toCross = () => {
    gsap.to(firstLineRef.current, { rotate: 45, attr: { x2: 28 } });
    gsap.to(secondLineRef.current, { autoAlpha: 0 });
    gsap.to(thirdLineRef.current, { rotate: -45, attr: { x2: 28 } });
  };

  const toBurger = () => {
    gsap.to(firstLineRef.current, { rotate: 0, attr: { x2: 25 } });
    gsap.to(secondLineRef.current, { autoAlpha: 1 });
    gsap.to(thirdLineRef.current, { rotate: -0, attr: { x2: 25 } });
  };

  const onClick = () => {
    const newState = !isDown;
    newState ? toCross() : toBurger();
    onChange(!isDown);
  };

  useEffect(() => {
    isDown && toCross();
    !isDown && toBurger();
  }, [ isDown ]);

  const onMouseEnter = () => !isDown && gsap.to(secondLineRef.current, { attr: { x2: 25 } });
  const onMouseLeave = () => !isDown && gsap.to(secondLineRef.current, { attr: { x2: 16 } });

  return (
    <div ref={wrapperRef}
         className="burger"
         onClick={onClick}
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21.5" viewBox="0 0 26 21.5">
        <line ref={firstLineRef} x2="26" transform="translate(0 0.75)" fill="none" stroke="#fff" strokeWidth="1.5"/>
        <line ref={secondLineRef} x2="16" transform="translate(0 10.75)" fill="none" stroke="#fff" strokeWidth="1.5"/>
        <line ref={thirdLineRef} x2="26" transform="translate(0 20.75)" fill="none" stroke="#fff" strokeWidth="1.5"/>
      </svg>
    </div>
  );

};

const Menu = () => {
  const intl = useIntl();
  const isMounted = useIsMounted();
  const locale = useLocaleContext();
  const fullMenuRef = useRef(null);
  const location = useLocation();
  const offsetGapRef = useRef<number>(100);
  const [ isSideVisible, setIsSideVisible ] = useState<boolean>(false);
  const [ isMainVisible, setIsMainVisible ] = useState<boolean>(true);

  useEffect(() => {
    window.addEventListener("scroll", () => onScroll());
    return () => {
      window.addEventListener("scroll", () => onScroll());
    };
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (location.pathname.includes("bien")) {
      gsap.to(fullMenuRef.current, {
        color: "var(--black)",
        backgroundColor: "var(--white)",
      });
      return;
    }
    ;
    gsap.to(fullMenuRef.current, {
      color: isMainVisible ? "var(--white)" : "var(--black)",
      backgroundColor: isMainVisible ? "inherit" : "var(--white)",
    });
  }, [ isMainVisible, location ]);

  const onScroll = () => setIsMainVisible(window.scrollY <= offsetGapRef.current);

  const navItemsRendering = useCallback((onClick: () => void = () => void 0) => (
    <nav>
      <ul className="nav-links">
        <li>
          <LocaleNavLink onClick={onClick} to="agence">{intl.formatMessage({
            id: "menu-agence",
            defaultMessage: "Agence"
          })}</LocaleNavLink>
        </li>
        <li>
          <LocaleNavLink onClick={onClick} to="services">{intl.formatMessage({
            id: "menu-services",
            defaultMessage: "Services"
          })}</LocaleNavLink>
        </li>
        <li>
          <LocaleNavLink onClick={onClick} to="nos-biens">{intl.formatMessage({
            id: "menu-nos-biens",
            defaultMessage: "Nos biens"
          })}</LocaleNavLink>
        </li>
        <li>
          <LocaleNavLink onClick={onClick} to="champex-lac">Champex-lac</LocaleNavLink>
        </li>
        <li>
          <LocaleNavLink onClick={onClick} to="contact">{intl.formatMessage({
            id: "menu-contact",
            defaultMessage: "Contact"
          })}</LocaleNavLink>
        </li>
        <li>
          <Language setIsDown={setIsSideVisible}/>
        </li>
      </ul>
    </nav>
  ), [ locale ]);

  return (
    <header>
      {/*Side menu removed for landing page*/}
      <aside>
        <LocaleNavLink to="/" className="logo">
          <LogoSvg/>
        </LocaleNavLink>
        <Burger isDown={isSideVisible} onChange={(val) => setIsSideVisible(val)}/>
      </aside>
      <section className={classNames("side-nav", { open: isSideVisible })}>
        {navItemsRendering(() => setIsSideVisible(false))}
      </section>
      <section ref={fullMenuRef}
               className={classNames("main-nav", { articlesPage: location.pathname.includes("bien") })}>
        <nav>
          <ul className="nav-links">
            <li>
              <LocaleNavLink to="agence">{intl.formatMessage({
                id: "menu-agence",
                defaultMessage: "Agence"
              })}</LocaleNavLink>
            </li>
            <li>
              <LocaleNavLink to="services">{intl.formatMessage({
                id: "menu-services",
                defaultMessage: "Services"
              })}</LocaleNavLink>
            </li>
            <li>
              <LocaleNavLink to="nos-biens">{intl.formatMessage({
                id: "menu-nos-biens",
                defaultMessage: "Nos biens"
              })}</LocaleNavLink>
            </li>
            <li>
              <LocaleNavLink to="champex-lac">Champex-lac</LocaleNavLink>
            </li>
            <li>
              <LocaleNavLink to="contact">{intl.formatMessage({
                id: "menu-contact",
                defaultMessage: "Contact"
              })}</LocaleNavLink>
            </li>
            <li>
              <Language setIsDown={setIsSideVisible}/>
            </li>
          </ul>
        </nav>
      </section>
    </header>);

};

export default Menu;
