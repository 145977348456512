import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { classNames } from "@ct-react/core";
import { SearchPaginationCriteria } from "../../models/search";
import { DataProps } from "../../utils/components";
import "./pagination.scss";

type PageInfoProps = {
  currentPage: number;
  pageSize: number;
  pageCount: number;
  previousPageCursor?: string;
  nextPageCursor?: string;
  linkPageCursors?: { page: number, cursor: string }[];
  lastPageCursor?: string;
}

type ResultPaginationProps = DataProps<PageInfoProps> & {
  onPageChange?: (params: SearchPaginationCriteria) => void
}

const ResultPagination = (
  {
    data,
    onPageChange = () => void 0
  }: ResultPaginationProps) => {

  const pageClasses = useCallback((comparer: number) =>
    classNames("pagination", { currentPage: data.currentPage === comparer }), [ data ]);

  return (
    <div className="articles-pagination">
      {data.pageCount > 1 &&
        <div className="pagination-container">
          <button type="button"
                  className="pagination pagination-before"
                  disabled={!data.previousPageCursor}
                  onClick={() => onPageChange(data.currentPage - 1 === 1
                    ? { first: data.pageSize }
                    : { last: data.pageSize, before: data.previousPageCursor })}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button type="button"
                  className={pageClasses(1)}
                  disabled={data.currentPage === 1}
                  onClick={() => onPageChange({ first: data.pageSize })}>1</button>
          {(!!data.linkPageCursors && data.linkPageCursors[0].page > 2) &&
            <button type="button"
                    className="pagination ellipsis"
                    disabled>
              <FontAwesomeIcon icon={faEllipsis} />
            </button>
          }
          {!!data.linkPageCursors && data.linkPageCursors.map(({ page: p, cursor: after }, i) => (
            <button key={i}
                    type="button"
                    className={pageClasses(p)}
                    disabled={data.currentPage === p}
                    onClick={() => onPageChange({ first: data.pageSize, after })}>{p}</button>
          ))}
          {(!!data.linkPageCursors && data.linkPageCursors[data.linkPageCursors.length - 1].page < data.pageCount - 1) &&
            <button type="button"
                    className="pagination ellipsis"
                    disabled>
              <FontAwesomeIcon icon={faEllipsis} />
            </button>
          }
          <button type="button"
                  className={pageClasses(data.pageCount)}
                  disabled={data.currentPage === data.pageCount}
                  onClick={() => onPageChange({ first: data.pageSize, after: data.lastPageCursor })}>{data.pageCount}</button>
          <button type="button"
                  className="pagination pagination-after"
                  disabled={!data.nextPageCursor}
                  onClick={() => onPageChange({ first: data.pageSize, after: data.nextPageCursor })}>
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      }
    </div>);

}

export default ResultPagination;
